<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import PrivacyPolicy from '/~/views/privacy-policy/privacy-policy.vue'

export default {
  name: 'drawer-privacy-policy',
  components: {
    PrivacyPolicy,
    BaseAsidePage,
  },
}
</script>

<template>
  <base-aside-page title="Privacy Policy">
    <privacy-policy />
  </base-aside-page>
</template>
